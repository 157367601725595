<div class="wrapper" [dir]="lang=='fa'?'rtl':'ltr'"
    [ngStyle]="{'direction':lang=='fa'?'rtl':'ltr','text-align':lang=='fa'?'right':'left','font-family':lang=='fa'?'vazir':'roboto'}">
    <div [ngClass]="lang=='fa'?'sidebar':'sidebar-ltr'" data-color="white">
        <app-sidebar></app-sidebar>
    </div>
    <div [ngClass]="lang=='fa'?'main-panel':'main-panel-ltr'">
        <app-navbar></app-navbar>
        <router-outlet></router-outlet>
        <app-footer></app-footer>
    </div>
</div>
