import {Inject, Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Router, RouterStateSnapshot, CanActivate, UrlTree} from '@angular/router';
import {CookieService} from 'ngx-cookie-service';
import { Observable } from 'rxjs';
// import {OidcSecurityService} from 'angular-auth-oidc-client';


@Injectable({providedIn: 'root'})
export class ManageLoginChildGuard implements CanActivate {
  constructor(private cookieService: CookieService,private router: Router,) {
  }

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    if (this.cookieService.get('token') == null || this.cookieService.get('token') == '' || this.cookieService.get('token') == undefined) {
      return true;
    } else if (this.cookieService.get('token') != null && this.cookieService.get('token') != '' && this.cookieService.get('token') != undefined) {
      this.router.navigate(['/user']);
      return false;
    } else {
      this.router.navigate(['/user'])
      return false;
    }
  }
}