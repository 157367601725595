import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service'
import { ToastrService } from 'ngx-toastr';
import { NEVER, Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
// import * as moment from 'jalali-moment';
declare var $: any;

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  developingMode = true;
  // api = 'https://app.language.medis.land/';
  api = 'https://app.shahin.codecraftstudio.com/';


  constructor(private http:HttpClient,
    private router: Router,
    private toastr: ToastrService,
    public cookieService: CookieService
    ) { }

  apiCall(method, route, body?, params?) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json;charset=UTF-8',
      Authorization: 'Bearer ' + this.cookieService.get('token'),
      deviceHash: '82d8a70c2ce951ba45aef4b5dd0dedad'
    });

    if ( method === 'post' ) {
      return this.http.post(this.api + route, body, {headers, observe: 'response'}).pipe(
        catchError(error => {
          if(error.status == 401) {
            this.cookieService.delete('token')
            this.router.navigate(['']);
          } else if (error.status == 403) {
            this.cookieService.delete('token')
            this.showNotification('دسترسی شما به این صفحه مسدود شده است. لطفا برای ورود دوباره تلاش کنید', 1)
            this.router.navigate(['']);
            return NEVER;
          } else {
            console.log('ERROR:',error);
            this.showNotification(error.error.Detail, 1)
          }
          return of([]);
        })
      )
    } else if ( method === 'get') {
      return this.http.get(this.api + route, {headers, observe:'response', params:params}).pipe(
        catchError(error => {
          if(error.status == 401){
            this.cookieService.delete('token')
            this.router.navigate(['']);
            return NEVER;
          } else if (error.status == 403) {
            this.cookieService.delete('token')
            this.showNotification('دسترسی شما به این صفحه مسدود شده است. لطفا برای ورود دوباره تلاش کنید', 1)
            this.router.navigate(['']);
            return NEVER;
          } else {
            console.log('ERROR:',error);
            this.showNotification(error.error.Detail, 1)
          }
          return of([]);
        })
      )
    } else if ( method === 'delete') {
      return this.http.delete(this.api + route, {headers, observe: 'response'}).pipe(
        catchError(error => {
          if (error.status == 401){
            this.cookieService.delete('token');
            this.router.navigate(['']);
            return NEVER;
          } else {
            console.log('ERROR:',error);
            this.showNotification(error.error.Detail, 1)
          }
          return of([]);
        })
      )
    } else if ( method === 'put') {
      return this.http.put(this.api + route, body, { headers ,observe:'response'}).pipe(
        catchError (error => {
          // console.log(error);
          
          if(error.status == 401){
            this.cookieService.delete('token')
            this.router.navigate(['']);
            return NEVER;
          } else {
            console.log('ERROR:',error);
            this.showNotification(error.error.Detail, 1)
          }
          return of([]);          
        })
      )
    }

  }

  apitest(method, route, body, params) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json;charset=UTF-8',
      Authorization: 'Bearer ' + this.cookieService.get('token'),
      deviceHash: '82d8a70c2ce951ba45aef4b5dd0dedad'
    });

    if ( method === 'get') {
      return this.http.get(this.api + route, {headers, observe:'response', params: params}).pipe(
        catchError(error => {
          if(error.status == 401){
            this.cookieService.delete('token')
            this.router.navigate(['']);
            return NEVER;
          } else if (error.status == 403) {
            this.cookieService.delete('token')
            this.showNotification('دسترسی شما به این صفحه مسدود شده است. لطفا برای ورود دوباره تلاش کنید', 1)
            this.router.navigate(['']);
            return NEVER;
          } else {
            console.log('ERROR:',error);
            this.showNotification(error.error.Detail, 1)
          }
          return of([]);
        })
      )
    }
  }
  

  sendFile(route, formData) {
    const headers= new HttpHeaders({
      'encrypt': 'multipart/form-data',
      Authorization: 'Bearer ' + this.cookieService.get('token')
    });
    return this.http.post(this.api + route , formData ,  
      {headers, observe: 'response'}).pipe(
      catchError(error => {
        console.log(error);
        this.showNotification(error.error.Detail, 1);
        if (error.status == 403) {
          this.router.navigate(['']);
          this.showNotification('شما به این صفحه دسترسی ندارید', 1);
        }
        return of([]);
      })
    )
  }   

  // getTimestamp($event) {
  //   const dateShamsiToMiladi = moment.from(Number($event.year) + '-' + Number($event.month) +
  //     '-' + Number($event.day), 'fa').format('YYYY-MM-DD');
  //   const datemiladi = dateShamsiToMiladi.split('-');
  //   const UTCDate = new Date(Number(datemiladi[0]), Number(datemiladi[1]) - 1, Number(datemiladi[2]));

  //   return (-(UTCDate.getTimezoneOffset()) * 60) + (UTCDate.getTime() / 1000);
  // } 

  showNotification( message, id) {
    if(id === 1){
        this.toastr.error('<span class="text-white pr-1">' + message +'</span>', '', {
          timeOut: 8000,
          closeButton: true,
          enableHtml: true,
          positionClass: 'toast-top-right'
        });
    } else if (id === 2) {
      this.toastr.success('<span class="text-white px-4">' + message +'</span>', '', {
        timeOut: 8000,
        closeButton: true,
        enableHtml: true,
        positionClass: 'toast-top-right'
      });
    } else if (id === 3) {
      this.toastr.warning('<span class="text-white px-4">' + message +'</span>', '', {
        timeOut: 8000,
        closeButton: true,
        enableHtml: true,
        positionClass: 'toast-top-right'
      });
    }
  }

  
  blobToBase64(blob) {
    return new Promise((resolve, _) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.readAsDataURL(blob);
    });
  }
}