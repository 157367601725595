import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from 'app/shared/services/api.service';
import { CookieService } from 'ngx-cookie-service';

@Component({
    selector: 'app-alert-dialog',
    styleUrls: ['./dialogStyle.component.scss'],
    template: `
  <div [dir]="lang=='fa'?'rtl':'ltr'" style="max-width:400px" [ngClass]="lang=='fa'?'rtl':'ltr'">
    <h4 class="text-center mt-0"> {{title|translate}} </h4>
    <hr>
    <h5 style="color:#6A6A6A;">
        {{message|translate}}
    </h5>
    <div class="row justify-content-between m-0" >
    <button (click)="onConfirmClick()" class="btn btn-primary" > {{'Button.Submit'|translate}} </button>
    <button (click)="closeDialog()" class="btn text-dark"> {{'Button.Cancle'|translate}} </button>
    </div>
  </div>
  `
})
export class ConfirmationDialogComponent {
    
    cancelButtonText = "Cancel"
    lang: any;
    id: any = 0;
    title: string = '';
    message: string = ""

    constructor(
        public apiService: ApiService,
        private route: ActivatedRoute,
        public cookieService: CookieService,
        @Inject(MAT_DIALOG_DATA) private data: any,
        private dialogRef: MatDialogRef<ConfirmationDialogComponent>) {
        if (data) {
            // console.log(data)
            this.message = data.message;
            this.title = data.title;
        }
        this.dialogRef.updateSize('600px', '300px')
        this.lang = this.cookieService.get('lang');
    }

    onConfirmClick(): void {
        this.dialogRef.close(true);
    }

    closeDialog() {
        this.dialogRef.close();
    }

}