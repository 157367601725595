
<footer class="footer">
  <div class="container-fluid">
    <div class="copyright d-flex justify-content-start">
      <a href="https://codecraftstudio.com/" target="_blank">
      <img src="../../../assets/img/CCS-Logo.png" width="50px" height="40px" alt="CCS Logo">
       Code Craft Studio
      &copy;
       <span> {{date | date: 'yyyy'}}</span>
      </a>
    </div>
  </div>
</footer>
