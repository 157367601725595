import { Component, OnInit} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {

  lang: any;

  constructor(private translate: TranslateService,
    public cookieService: CookieService) 
    {
      this.lang = this.cookieService.get('lang')

      translate.setDefaultLang(this.lang);
      translate.use(this.lang);

    }

    ngOnInit(){
      // if(this.lang == 'fa') {
      //   require("style-loader!../assets/scss/now-ui-dashboard.scss");
      // } else {
      //   require("style-loader!../assets/scss/now-ui-dashboard-ltr.scss");
      // }
    }

}
