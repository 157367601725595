
<div class="logo">
      <div class="logo-img">
          <img src="../../../assets/img/logo.png"/>
      </div>
    <span class="simple-text logo-normal">
    {{'Menu.ShahinLanguage'|translate}}
    </span>
</div>
<div class="sidebar-wrapper">
    <ul class="nav">
        <li routerLinkActive="active" *ngFor="let menuItem of menuItems" class="{{menuItem.class}} nav-item">
            <a [routerLink]="[menuItem.path]">
                <i class="now-ui-icons {{menuItem.icon}}"></i>
                <p>{{menuItem.title | translate}}</p>
            </a>
        </li>
    </ul>
</div>
