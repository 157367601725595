import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

declare interface RouteInfo {
    path: string;
    title: string;
    icon: string;
    class: string;
}
export const ROUTES: RouteInfo[] = [
    { path: '/user', title: 'Menu.Users',  icon: 'users_single-02', class: '' },
    { path: '/content', title: 'Menu.Contents',  icon:'education_paper', class: '' },
    { path: '/report' , title: 'Menu.Reports',  icon:'business_chart-bar-32', class: '' },
    { path: '/financial', title: 'Menu.Financial',  icon:'business_money-coins', class: '' },
    { path: '/feedback', title: 'Menu.FeedBack',  icon:'ui-2_like', class: '' },
    { path: '/setting', title: 'Menu.Settings',  icon:'loader_gear', class: '' }    

];

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {
  menuItems: any[];

  constructor(
    public translate: TranslateService
  ) { }

  ngOnInit() {
    this.menuItems = ROUTES.filter(menuItem => menuItem);
  }
  isMobileMenu() {
      if ( window.innerWidth > 991) {
          return false;
      }
      return true;
  };
}
