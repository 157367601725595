
<nav class="navbar navbar-expand-lg navbar-transparent  navbar-absolute bg-primary fixed-top">
    <div class="container-fluid">
        <div class="navbar-wrapper">
            <div class="navbar-toggle">
                <button type="button" class="navbar-toggler" (click)="sidebarToggle()">
                    <span class="navbar-toggler-bar bar1"></span>
                    <span class="navbar-toggler-bar bar2"></span>
                    <span class="navbar-toggler-bar bar3"></span>
                </button>
            </div>
            <h4> <a class="navbar-brand" href="#pablo">{{getTitle() | translate}}</a></h4>
        </div>
        <button class="navbar-toggler" type="button" (click)="collapse()"
          [attr.aria-expanded]="!isCollapsed" aria-controls="collapseExample">
          <span class="navbar-toggler-bar navbar-kebab"></span>
          <span class="navbar-toggler-bar navbar-kebab"></span>
          <span class="navbar-toggler-bar navbar-kebab"></span>
        </button>
       
        <div class="collapse navbar-collapse justify-content-end" id="collapseExample" [ngbCollapse]="isCollapsed">

          <ul class="navbar-nav">
            <li class="nav-item" ngbDropdown>
              <a class="nav-link" id="dropdownBasic1" ngbDropdownToggle>
                <i class="now-ui-icons location_world"></i>
                <p>
                  <span class="d-lg-none d-md-block">lang</span>
                </p>
              </a>
              <div class="dropdown-menu" [ngClass]="lang=='fa'?'dropdown-menu-left':'dropdown-menu-right'" ngbDropdownMenu aria-labelledby="dropdownBasic1">
                <a class="dropdown-item" (click)="switchLanguage('fa')" style="font-family:'vazir';">فارسی</a>
                <a class="dropdown-item" (click)="switchLanguage('en')">English</a>
              </div>
            </li>
          </ul>

          <ul class="navbar-nav p-0">
            <li class="nav-item" ngbDropdown>
              <a class="nav-link" id="dropdownBasic1" ngbDropdownToggle>
                <i class="now-ui-icons users_single-02"></i>
                <p>
                  <span class="d-lg-none d-md-block">Profile</span>
                </p>
              </a>
              <div class="dropdown-menu" [ngClass]="lang=='fa'?'dropdown-menu-left':'dropdown-menu-right'" ngbDropdownMenu aria-labelledby="dropdownBasic1">
                <a class="dropdown-item" [routerLink]="['/profile']"> {{'General.Profile'|translate}} </a>
                <a class="dropdown-item" (click)="logOut()"> {{'General.Logout'|translate}} </a>
              </div>
            </li>
          </ul>
        </div>
    </div>
</nav>
